
.sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;
    /* overflow: auto */
}


.important-topics-content {
    background: transparent;
    padding: 40px;
    border-radius: 15px;
    transition: .4s ease-in-out;
    height: 250px;
    border: 1px solid #fff;
}

.important-topics-content .img-wrapper {
    width: 100px;
    height: 100px;
    /* background-color: #ff6161;
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    /* border: 2px solid #fff; */
}

.user-row span svg {
    width: 30px !important;
}

.popular-videos .close {
    color: #fff;
    opacity: 1;
}

.popular-videos .modal-header{
    border-bottom: 2px solid #fff !important;
}

.gallery .media-wrapper {
    margin-bottom: 30px;
}

.view-all-text {
    font-size: 18px;
}

.proj-btn .img-wrapper svg {
    width:20px;
}

.custom-wrapper h3 {
    font-size: 32px;
}

.title-area p{
    font-size: 15px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
}

.slide-sec .add-more-image{
    background: #00779c;
    border-radius: 50px;
    padding: 15px 20px;
    /* width: 30px;
    height: 30px; */
}

/* #upload-file {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    background: orange;
    border-radius: 100%;
    cursor: pointer;
    top: 0;
} */

/* .custom-file-upload {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 65px);
    margin-bottom: 0;
    top: 0;
    left: 0;
    background: red;
    font-size: 1px;
    border-radius: 50%;
    opacity: 0;
} */

.user-img {
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.user-img{ justify-content: initial !important;}

.user-img:hover {
    cursor: pointer;
}

.user-img:hover .upload-icon {
    opacity: 1;
}

.upload-icon svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    display: inline-block;
    font-size: 25px;
    /* padding: 10px 18px; */
    /* border-radius: 6px; */
    /* font-weight: 500; */
}

.user-img .upload-icon{
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.9);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    border-radius: 6px;
}

.user-img img {
    /*transform: scale(3);*/
}

/* .user-img .custom-file {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 65px);
    margin-bottom: 0;
    top: 0;
    left: 0;
    background: red;
    font-size: 1px;
    border-radius: 50%;
    opacity: 0;
} */

.user-img .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    background: orange;
    border-radius: 100%;
    cursor: pointer;
}


.account-page-body .banner .user-details label{
    padding: 12px 30px;
    /* cursor: pointer; */
    border-radius: 30px;
    background: #00cb7a;
    background: linear-gradient(to right, #00cb7a 0%,#0091c8 50%,#00cb7a 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cb7a', endColorstr='#00cb7a',GradientType=1 );
    background-size: 200% auto;
    text-decoration: none !important;
    transition: all 0.5s ease !important;
}

.inner-page-profile {
    padding: 110px 0px;
}

.user-profile-img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    cursor: pointer;
    transition: .2s ease-in-out;
    overflow: hidden;
    position: relative;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-profile-img img {
    /*transform: scale(2);*/
}

.media-wrapper.test3 img{ height: 201px;}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.homeModal .embed-container{ padding-bottom: 66%;}

/* PROJECTS TABB */


.project-count {
    position: relative;
    color: #a2a2a2;
    font-size: 25px;
    font-weight: 200;
    margin-left: 30px!important;
}

.project-button-search .input-group {
    background: #fff;
    border-radius: 50px;
    padding: 5px;
}

.project-button-search input {
    background: #fff;
    border-radius: 50px!important;
    border: 0 solid;
}

.project-button-search .input-group-text {
    background: transparent;
    height: 100%;
    border: 0;
}

.gradiant-button-pb:hover {
    background-position: 100%;
    color: #fff;
    text-decoration: none;
}

.gradiant-button-pb {
    background-image: linear-gradient(
90deg,#00cb7a 0,#0091c8 51%,#00cb7a);
    margin: 10px;
    padding: 12px 30px;
    text-align: center;
    transition: .5s;
    background-size: 200% auto;
    color: #fff;
    border-radius: 50px;
    display: block;
    border-style: hidden;
}
.create-playlist-btn {
    margin: 0 15px!important;
}
.btn-text, .create-playlist-btn {
    font-weight: 400;
    font-size: 16px;
}

.my-video-projects-header {
    padding-bottom: 40px;
    border-bottom: 2px solid #fff;
    /* padding-bottom: 25px; */
}

.loadinggif {
    width: 70px;
    margin-bottom: 20px;
}

.audio-add-text-box textarea {
    color: #fff !important;
}
.audio-add-text-box textarea::-webkit-input-placeholder { /* Edge */
    color: #fff;
  }
  
.audio-add-text-box textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #fff;

}

.audio-add-text-box textarea::placeholder {
color: #fff;
}

.in-out-upload-box h6 {
color: #fff;
}

.script-box label{
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 4;
    color: #747474 !important;
}

.script-box textarea {
    color: #fff!important;
    padding: 40px 16px 16px;
    border-radius: 4px!important;
    border: 1px solid #424344;
    background-color: #000!important;
    height: auto;
    box-shadow: none;
    resize: none;
}

.script-box textarea:focus ,.script-box textarea:hover {
    box-shadow: 0 0 20px 0 #000;
    border-color: #9e9e9e;
}

.script-box textarea {
    color: #fff!important;
}

.script-box .btn-create{
    padding: 23px 0;
    border-radius: 40px;
    border: none;
    background: #3686ff;
    background: -moz-linear-gradient(-45deg, #3686ff 0%, #c867ff 50%, #3686ff 99%);
    background: -webkit-linear-gradient(-45deg, #3686ff 0%,#c867ff 50%,#3686ff 99%);
    background: linear-gradient(135deg, #3686ff 0%,#c867ff 50%,#3686ff 99%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );
    background-size: 200% auto;
    width: 100%;
    color: #fff;
    font-size: 14px;
}

.script-box .btn-create:hover{
    background-position: right;
}


.btn-grad{
    padding: 8px 15px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    background: #3686ff;
    background: -moz-linear-gradient(-45deg, #3686ff 0%, #c867ff 50%, #3686ff 99%);
    background: -webkit-linear-gradient(-45deg, #3686ff 0%,#c867ff 50%,#3686ff 99%);
    background: linear-gradient(135deg, #3686ff 0%,#c867ff 50%,#3686ff 99%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );
    background-size: 200% auto;
    color: #fff;
    font-size: 14px;
    min-width: 100px;
}

.btn-grad:hover{
    background-position: right;
}

.script-box .btn-create:active, .script-box .btn-create:focus{
    background-position: right !important;
    background-image: #fff !important;
}

.user-img:hover .upload-icon {
    opacity: 1;
}
.search-load.media-upload{
    position: relative;
}
.search-load.media-upload:hover .deleteIcon{
    opacity: 1;
}
.deleteIcon{
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.9);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    border-radius: 6px;
}
.deleteIcon svg {
    position: absolute;
    top: 5px;
    right: 5px;
    /* top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    color: #fff;
    display: inline-block;
    font-size: 16px;
    /* padding: 10px 18px; */
    /* border-radius: 6px; */
    /* font-weight: 500; */
}

/* .search-load-box #scrollableEmoji{
    height: 70vh;
    overflow: scroll;
} */

/* .header .list a:hover, .header .list a:active {
    border-bottom: 2px solid #00c780;
} */

/* .my-video-projects-card {
    border-top: 2px solid #fff;
    padding-top: 25px;
} */


.tooltip-cus {  
    display: contents;
    /* display: inline;   */
    position: relative;  
    text-decoration: none;  
    top: 0px;  
    left: 4px;  
    font-size: 12px;
    width: 100%;
}
.tooltip-cus:after{  
    background: #000;  
    border: 1px solid #fff;
    border-radius: 5px;  
    top: 30px;  
    color: #fff;  
    content: attr(alt);  
    left: 160px;  
    padding: 5px 15px;  
    position: absolute;  
    z-index: 98;  
    width: max-content;  
    /* display: none !important; */
}
.tooltip-cus:after{  
    display: none !important;
}
.tooltip-cus:hover:after{  
    display: block !important;
}  
.tooltip-cus:before{
    background: #000;
    bottom: 20px;  
    content: "";  
    left: 155px;  
    position: absolute;  
    z-index: 99;  
    top: 3px;  
}


.metaverse-img{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
    width: 100%;
    height: auto;
    /* max-height: 300px; */
    margin-bottom: 10px;
}
.metaverse-img img{
    transform: scale(2);
    height: 300px;
}

.metaverse-img-alt img{
    transform: scale(1);
    height: auto;
}

.metaverse-video-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
    width: 100%;
    height: auto;
}

.metaverse-video-box img{
    transform: scale(1);
    height: 300px;
}

.video-menu-tabs .list-group-item+.list-group-item.active{ margin: 0 !important;}
.panImg .metaverse-img-alt{ height: 200px; margin-bottom: 0;}
.panImg .metaverse-img-alt img{ height: 100%;}
.panImg [class*='col-']{ margin-bottom: 30px;}


@media (max-width: 1599px) {
    .homeModal .modal-body{ height: 600px;}
    .commonModal .modal-dialog{ max-width: 850px !important;}
    .homeModal .embed-container{ padding-bottom: 0; height: 100%;}
    .homeModal .modal-dialog{ max-width: 720px !important;}
    /* .homeModal .embed-container{ padding-bottom: 56%;} */
}

@media (max-width: 767px) {
    .homeModal .modal-body{ height: 256px;}
}


.preview-selected{
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
}

.preview-selected button{
    background: linear-gradient(to right, #ffffff 0%,#ffffff 50%,#00ffba 50%,#3b91cb 100%);
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 2px 10px;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 13px;
}