.footer {
  background: rgba(0 0 0 / 50%);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 25px 0;
}

.footer .custom-wrapper {
  max-width: 90%;
  margin: 0 auto;
}

.footer li:not(:last-child) {
  margin-right: 40px;
}

.footer li a {
  color: #fff;
}
.footer li a:hover {
  color: #00d47f;
}

.custom-wrappern.container-fluid{ max-width: 100%; padding-left: 50px; padding-right: 50px;}

@media screen and (max-width: 1366.98px) {
  .footer {
    padding: 15px 0;
  }

  .footer .fs-18 {
    font-size: 14px;
  }
}

@media screen and (max-width: 1024.98px) {
  /* .footer {
    position: unset;
  } */
  
  .footer .fs-18 {
    font-size: 12px;
  }
}

@media screen and (max-width: 991.98px) {
  .footer {
    background: rgba(0 0 0 / 100%);
    padding: 25px 0;
  }

  .footer .img-wrapper img {
    margin: 5px auto;
  }

  .footer li:not(:last-child) {
    margin-right: 30px;
  }

  /* .footer .fs-18 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  } */

  /* .footer .list {
    flex-wrap: wrap;
    text-align: center;
  } */
}

/* @media (min-width: 2550px) {
  .footer .custom-wrapper{ max-width: 70%;}
} */