.ac-page-main {
  background: #000E34;
}

.ac-page-main footer {
  position: unset;
  background: #00113E;
}

.privacy-account {
  padding: 50px 0 100px;
}

.create-acc-sec {
  margin-bottom: 40px;
}

.create-acc-sec .form-wrapper,
.create-acc-table-sec .table-wrapper {
  background: #081030;
  border-radius: 32px;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 37%);
}

.create-acc-sec .form-wrapper {
  padding: 60px 45px;
}

.account-data-sec .account-data-box {
  padding: 40px;
}

.create-acc-sec .content-wrapper,
.create-acc-table-sec .content-wrapper {
  max-width: 1435px;
}

.create-acc-sec .form-group input {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
}

.create-acc-sec .form-wrapper .form-group {
  background: #1B344E;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 18px 35px;
  flex: 0 0 50%;
  max-width: 49%;
}

/* .create-acc-sec .create-acc-submit {
  width: 100%;
  background: #3686ff;
  background: -moz-linear-gradient(-45deg,  #3686ff 0%, #c867ff 50%, #c867ff 50%, #3686ff 100%);
  background: -webkit-linear-gradient(-45deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  background: linear-gradient(135deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );
  background-size: 200% auto;
  margin-top: 30px;
  padding: 25px 0;
  border-radius: 100px;
} */

.create-acc-sec .create-acc-submit:hover {
  background-position: right;
}

.create-acc-table-sec .action-btn .img-wrapper {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-acc-table-sec td.action-btn {
  border: none;
}

.create-acc-table-sec th {
  background: #1B344E;
  border: none!important;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.create-acc-table-sec tbody tr td,
.create-acc-table-sec th {
  padding: 24px 67px;
}

.create-acc-table-sec tbody tr td {
  border: none;
  color: #B4B4B4;
  font-family: 'Poppins', sans-serif;
}

.create-acc-table-sec tbody tr {
  background: #081030;
  color: #fff;
  border-bottom: 1px solid #676767;
}

.create-acc-table-sec .table-wrapper {
  padding-bottom: 25px;
  background: #081030;
  border-radius: 32px;
  overflow: hidden;
}

.create-acc-table-sec {
  margin: 60px 0;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
  }
}

@media screen and (max-width: 1680.98px) {

}

@media screen and (max-width: 1500.98px) {
  .privacy-account .custom-wrapper {
    max-width: 90%;
  }
}

@media screen and (max-width: 1440.98px) {

}

@media screen and (max-width: 1366.98px) {
  .create-acc-table-sec tbody tr td, .create-acc-table-sec th {
    padding: 24px 40px;
    font-size: 14px;
  }

}

@media screen and (max-width: 1280.98px) {

}

@media screen and (max-width: 1199.98px) {
  .ac-page-main h6,
  .ac-page-main .md-txt {
    font-size: 16px;
  }

  .create-acc-sec .create-acc-submit {
    margin-top: 25px;
  }

  .create-acc-sec .content-wrapper, .account-data-sec .content-wrapper {
    max-width: 100%;
  }

  .privacy-account .custom-wrapper {
    max-width: 90%;
  }
}

@media screen and (max-width: 1024.98px) {
  .account-data-box .text-wrapper {
    flex: 0 0 55%;
    max-width: 55%;
  }

  .account-data-box .btn-wrapper {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .account-data-box .row {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 991.98px) {
  .privacy-account {
    padding: 120px 0 50px;
  }
}

@media screen and (max-width: 832.98px) {
  .privacy-account .custom-wrapper {
    max-width: 95%;
  }

  .create-acc-table-sec tbody tr td, .create-acc-table-sec th {
    padding: 15px 20px;
  }

  .create-acc-table-sec .table-wrapper {
    border-radius: 10px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767.98px) {

}

@media screen and (max-width: 640.98px) {
  .create-acc-sec .form-wrapper .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .create-acc-sec .form-wrapper .form-group.colmn-1 {
    margin-bottom: 20px!important;
  }

  .account-data-box .text-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .account-data-box .btn-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }

  .create-acc-table-sec .table {
    width: 950px;
  }

  .create-acc-table-sec .table-wrapper {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 575.98px) {

}

@media screen and (max-width: 440.98px) {
  .privacy-account h4,
  .privacy-account .h4 {
    font-size: 18px;
  }

  .create-acc-sec .form-wrapper {
    padding: 30px 25px;
    border-radius: 20px;
  }

  .create-acc-sec .form-wrapper .form-group {
    padding: 18px 15px;
  }

  .create-acc-sec .create-acc-submit {
    padding: 15px 0;
  }

  .account-data-sec .account-data-box {
    padding: 20px;
  }

  .ac-page-main h6, .ac-page-main .md-txt {
    font-size: 14px;
  }
}

@media screen and (max-width: 360.98px) {
  .privacy-account {
    padding: 100px 0 30px;
  }

  .account-data-box .row {
    padding: 35px 20px;
  }
}


/*edit*/
.edit-user .card,
.edit-member .card {
  border: none;
}

.edit-user h6{
  font-size: 14px;
}

.edit-user .card-body,
.edit-member .card-body {
  background: #081030;
  padding: 10px 60px 40px;
}

.edit-acc-form .form-group {
  background: #1b344e;
  flex: 0 0 50%;
  padding: 5px 10px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
}

.edit-acc-form .form-group input {
  width: 100%;
  background: transparent;
  outline: none;
  border: navajowhite;
  color: #ffffff;
}

.edit-acc-form .btn {
  margin: 10px 0px 40px;
  color: #fff;
  padding: 12px 30px;
}
.edit-acc-form .cancel-update {
  margin: 0;
  padding: 0;
}

.client-account{
  background: #1B344E;
  border: none;
  padding: 0;
  font: 400 16px/26px 'Poppins', sans-serif;
  color: #fff;
}

.client-account:focus{
  background: #1B344E;
  color: #fff;
  box-shadow: none;
}